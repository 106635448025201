<template>
  <custom-chart
    class="chart-device"
    type="donut"
    :options="deviceOptions"
    :series="deviceTopCountriesSeries"
    height="400px"
  />
</template>
<script>
  import CustomChart from '@/components/CustomChart.vue'

  export default {
    name: 'OfferDeviceTypeChart',
    components: {
      CustomChart
    },
    props: {
      site: {
        type: Object,
        required: true
      }
    },
    computed: {
      deviceOptions() {
        return {
          dataLabels: {
            enabled: false
          },
          legend: {
            position: 'bottom',
            dataLabels: true,
            labels: {
              colors: this.$vuetify.theme.themes.light.black
            },
            markers: {
              width: 10,
              height: 10
            }
          },
          plotOptions: {
            pie: {
              customScale: 1
            }
          },
          labels: this.deviceChartLabels,
          colors: [
            '#1C4492',
            '#43BCA3'
          ]
        }
      },
      deviceTopCountriesSeries() {
        return [+this.site.siteReport.similarwebPercentDesktopVisit, +this.site.siteReport.similarwebPercentMobileWebVisit]
      },
      deviceChartLabels() {
        return [
          `<b>${this.$t('marketplace.desktop')}</b> - ${this.deviceVisit.desktop}%`,
          `<b>${this.$t('marketplace.mobile')}</b> - ${this.deviceVisit.mobile}%`
        ]
      },
      deviceVisit() {
        return {
          desktop: (this.site.siteReport.similarwebPercentDesktopVisit * 100).toFixed(),
          mobile: (this.site.siteReport.similarwebPercentMobileWebVisit * 100).toFixed()
        }
      }
    }
  }
</script>
