<template>
  <div class="fourth__deal">
    <img
      src="@/assets/images/offer/fourth-label.png"
      class="fourth__deal-img"
      width="411"
      height="121"
      alt="Label"
    />
    <div class="fourth__deal-info">
      <div v-for="card in cardDealInfo" :key="card.id" class="deal-card">
        <div class="deal-card__value">
          {{ card.value }}
        </div>
        <div class="deal-card__name">
          {{ card.name }}
        </div>
      </div>
    </div>
    <div class="fourth__deal-discount-info">
      <div v-for="card in cardDealDiscountInfo" :key="card.id" class="deal-card">
        <div class="deal-card__name">
          {{ card.name }}
        </div>
        <div :class="['deal-card__value', { 'text-decoration-line-through error--text': card.crossedOut }]">
          {{ card.value }}
        </div>
      </div>
    </div>
    <div class="fourth__deal-price">
      <div class="price-name">
        Total price:
      </div>
      <div class="price-value">
        {{ marketplaceTotalPrice }}
      </div>
    </div>
  </div>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import FlatDeal from '@/services/classes/FlatDeal.js'

  export default {
    name: 'OfferFourthMain',
    props: {
      flatDeal: {
        type: FlatDeal,
        required: true
      }
    },
    computed: {
      marketplaceTotalPrice() {
        return `$${toFixedByDefault(this.flatDeal.price)}`
      },
      marketplaceDays() {
        return this.flatDeal.days
      },
      marketplaceImpressions() {
        return Number(this.flatDeal.totalTraffic).toLocaleString()
      },
      cardDealInfo() {
        return [
          {
            id: 1,
            value: this.marketplaceImpressions,
            name: 'Impressions'
          },
          {
            id: 2,
            value: this.marketplaceDays,
            name: 'Days'
          }
        ]
      },
      cardDealDiscountInfo() {
        return [
          {
            id: 1,
            value: `$${toFixedByDefault(this.flatDeal.flatBid)}`,
            name: 'CPM',
            crossedOut: false
          },
          {
            id: 1,
            value: this.totalDiscountRatioToPercent,
            name: 'Discount',
            crossedOut: false
          },
          {
            id: 3,
            value: `$${toFixedByDefault(this.flatDeal.originalPrice)}`,
            name: 'Old price',
            crossedOut: true
          }
        ]
      },
      totalDiscountRatioToPercent() {
        const totalDiscountRatioToPercent = (1 - this.flatDeal.totalDiscountRatio) * 100
        return `${totalDiscountRatioToPercent.toFixed(2)}%`
      }

    },
    methods: {
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/offer-deal-colors.scss";

  .deal-card {
    display: flex;
    flex-direction: column;
    gap: 3px;

    &__value {
      color: $black-color;
      font-size: 44px;
      font-weight: 600;
      line-height: normal;
    }
    &__name {
      color: $darker-color;
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
    }
  }
  .fourth__deal {
    padding: 142px 32px 26px;
    background: linear-gradient(125deg, $white-color 0%, rgba(255, 255, 255, 0.00) 100%);
    position: relative;

    &-img {
      position: absolute;
      top: 2px;
      left: -22px;
    }
    &-info {
      display: flex;
      gap: 80px;
      padding-bottom: 56px;
      border-bottom: 2px solid $light-color;
    }
    &-discount-info {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
    }
    &-price {
      margin-top: 100px;
      text-align: center;
      font-weight: 600;
      line-height: normal;
      color: $white-color;
      position: relative;
      z-index: 1;

      .price-name {
        text-shadow: 0 2px 6px rgba(0, 0, 0, 0.73);
        font-size: 20px;
      }
      .price-value {
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.69);
        font-size: 68px;
      }
    }
  }
</style>
