<template>
  <section class="first">
    <div class="first__header">
      <img
        src="@/assets/images/offer/first-logo.svg"
        class="image first__header-logo"
        width="192"
        height="55"
        alt="logo"
      />
      <img
        src="@/assets/images/offer/first-background.png"
        class="image"
        width="794"
        height="370"
        alt="background"
      />
      <img
        src="@/assets/images/offer/first-gifts.png"
        class="image first__header-gifts"
        width="352"
        height="278"
        alt="gifts"
      />
    </div>
    <div class="benefits">
      <h2 class="benefits__title">
        Exclusive placement offer and Direct placement offer
      </h2>
      <ul class="benefits__list">
        <li v-for="benefit in benefits" :key="benefit.id" class="benefit">
          <span class="benefit__number">{{ `0${benefit.id}` }}</span>
          <span>{{ benefit.text }}</span>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'OfferFirst',
    data() {
      return {
        benefits: [
          {
            id: 1,
            text: 'Fixed CPM'
          },
          {
            id: 2,
            text: 'No competitors on the website/bucket of websites'
          },
          {
            id: 3,
            text: 'Stable flow of traffic on a daily basis'
          },
          {
            id: 4,
            text: 'Fixed period of a deal'
          },
          {
            id: 5,
            text: 'Huge amount of direct and organic traffic, with most expensive slices/zones of traffic being included'
          },
          {
            id: 6,
            text: 'Total volume includes self-service, RTB and mediation traffic'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/offer-deal-colors.scss";

  .image {
    max-width: 100%;
    display: block;
  }
  .first {
    background-color: $primary-color;

    &__header-logo {
      position: absolute;
      top: 25px;
      left: 0;
      min-width: 192px;
    }
    &__header-gifts {
      position: absolute;
      top: 132px;
      right: -20px;
      min-width: 352px;
    }
  }

  .benefits {
    isolation: isolate;
    background-color: $primary-color;
    padding: 30px 54px;

    &__title {
      color: $white-color;
      font-size: 44px;
      font-weight: 600;
      padding-right: 50px;
    }
    &__list {
      padding: 0;
      list-style: none;
    }
  }

  .benefit {
    display: flex;
    align-items: center;
    padding: 12px 0;
    color: $white-color;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: normal;
    gap: 52px;

    &__number {
      font-family: 'Montserrat', sans-serif;
      font-size: 54px;
      font-weight: 600;
      width: 2ch;
    }

    &:not(:last-child) {
      border-bottom: 2px solid $white-color;
    }
  }
</style>
