<template>
  <header class="fourth__info">
    <div v-for="card in infoCards" :key="card.id" class="info-item">
      <img
        :src="card.img.src"
        :width="card.img.width"
        :height="card.img.height"
        :alt="card.img.alt"
      />
      <div class="info-item__content">
        <div class="content-name">
          {{ card.name }}
        </div>
        <div class="content-value">
          {{ card.value }}
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import FlatDeal from '@/services/classes/FlatDeal.js'

  export default {
    name: 'OfferFourthHeader',
    props: {
      flatDeal: {
        type: FlatDeal,
        required: true
      }
    },
    computed: {
      marketplaceImpressionsMin() {
        return `${toFixedByDefault(this.flatDeal.trafficPerMonth / 10 ** 6)}m`
      },
      infoCards() {
        return [
          {
            id: 1,
            img: {
              src: require('@/assets/images/offer/speaker.svg'),
              width: 48,
              height: 48,
              alt: 'Speaker'
            },
            name: 'Spot',
            value: this.flatDeal.placementType.name
          },
          {
            id: 2,
            img: {
              src: require('@/assets/images/offer/map-point.svg'),
              width: 48,
              height: 48,
              alt: 'Map point'
            },
            name: 'Countries group',
            value: this.flatDeal.countryGroup.name
          },
          {
            id: 3,
            img: {
              src: require('@/assets/images/offer/like.svg'),
              width: 48,
              height: 48,
              alt: 'Like'
            },
            name: 'Impressions',
            value: this.marketplaceImpressionsMin
          },
          {
            id: 4,
            img: {
              src: require('@/assets/images/offer/device-type.svg'),
              width: 48,
              height: 48,
              alt: 'Device type'
            },
            name: 'Device type',
            value: this.flatDeal.trafficType
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/offer-deal-colors.scss";

  .fourth__info {
    padding: 40px 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 36px;

    .info-item {
      display: flex;
      flex: 1 0 calc(100% / 2 - 36px);
      gap: 24px;

      &__content {
        display: flex;
        flex-direction: column;
        line-height: normal;
        font-size: 20px;

        .content-name {
          color: $darker-color;
          font-family: 'Roboto', sans-serif;
        }
        .content-value {
          display: flex;
          align-items: center;
          gap: 8px;
          color: $black-color;
          font-weight: 600;
          margin-top: 4px;

          &:last-child {
            text-transform: capitalize;
          }
        }
      }
    }
  }
</style>
