<template>
  <section class="fourth">
    <offer-fourth-header :flat-deal="flatDeal" class="fourth__header offer-card" />
    <offer-fourth-main class="fourth__main" :flat-deal="flatDeal" />
    <offer-fourth-footer class="fourth__footer" />
    <img src="@/assets/images/offer/fourth-blob.webp" class="fourth__img" width="794" height="376" alt="Blob" />
  </section>
</template>

<script>
  import FlatDeal from '@/services/classes/FlatDeal.js'
  import OfferFourthHeader from './OfferFourthHeader.vue'
  import OfferFourthMain from './OfferFourthMain.vue'
  import OfferFourthFooter from './OfferFourthFooter.vue'

  export default {
    name: 'OfferFourth',
    components: {
      OfferFourthHeader,
      OfferFourthMain,
      OfferFourthFooter
    },
    props: {
      site: {
        type: Object,
        required: true
      },
      flatDeal: {
        type: FlatDeal,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/offer-deal-colors.scss";

  .offer-card {
    border-radius: 16px;
    background: $white-color;
    box-shadow: 4px 12px 20px 0 rgba(21, 37, 94, 0.15);
  }
  .fourth {
    background: $lighter-color;
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    position: relative;

    &__img {
      position: absolute;
      bottom: 30px;
      left: 0px;
      max-width: 100%;
      width: 100%;
      height: auto;
      display: block;
    }
    &__header {
      margin-inline: 100px;
    }
    &__main {
      margin: 60px 100px 0 100px;
    }
    &__footer {
      margin-top: auto;
    }
  }
</style>
