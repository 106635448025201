<template>
  <custom-chart
    class="second__channels-chart"
    type="bar"
    :options="optionsChanel"
    :series="overviewsSeries"
  />
</template>

<script>
  import CustomChart from '@/components/CustomChart.vue'

  export default {
    name: 'OfferChannelOverviewsChart',
    components: {
      CustomChart
    },
    props: {
      site: {
        type: Object,
        required: true
      }
    },
    computed: {
      optionsChanel() {
        return {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            }
          },
          colors: [
            '#43BCA3',
            '#85D9FD',
            '#1C4492',
            '#7888DB',
            '#DFB6F2',
            '#BC4284'
          ],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
              dataLabels: {
                position: 'top'
              }
            }
          },
          dataLabels: {
            enabled: true,
            formatter: (val) => `${((val * 100) / this.trafficSourcesTotal).toFixed()}%`,
            offsetY: -20,
            style: {
              fontSize: '12px',
              fontFamily: '"Roboto", sans-serif',
              colors: [this.$vuetify.theme.themes.light.black]
            }
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: this.overviewsLabels,
            labels: {
              rotateAlways: true,
              offsetY: 10,
              rotate: -45,
              style: {
                colors: this.site.siteReport.similarwebTrafficSources.map(() => this.$vuetify.theme.themes.light['secondary-darken']),
                fontSize: '12px',
                fontFamily: 'Roboto, sans-serif'
              }
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            labels: {
              show: false,
              formatter: (val) => `${val}`
            }
          },
          grid: {
            show: false
          }
        }
      },
      overviewsSeries() {
        return [{
          name: 'Value',
          data: this.trafficSources
        }]
      },
      trafficSourcesTotal() {
        return this.trafficSources.length ? this.trafficSources.reduce((a, b) => a + b) : 1
      },
      overviewsLabels() {
        return this.site.siteReport.similarwebTrafficSources.map(({ type }) => type)
      },
      trafficSources() {
        return this.site.siteReport.similarwebTrafficSources.map(({ organicViews, paidViews }) => organicViews + paidViews)
      }
    }

  }
</script>
