import SiteNew from '@/services/classes/SiteNew.js'
import PlacementType from '@/services/classes/PlacementType.js'

export default class FlatDeal {
  countryGroup = { name: '', id: null }
  flatBid = null
  id = null
  isHotOffer = false
  maxDays = null
  maxTraffic = null
  minDays = null
  minTraffic = null
  originalPrice = null
  placementType = new PlacementType()
  price = null
  pricingModel = ''
  site = new SiteNew()
  totalDiscountRatio = null
  trafficPerMonth = null
  trafficType = ''
  days = null
  totalTraffic = null

  constructor(data) {
    if (!data) return

    this.countryGroup = {
      name: data.country_group?.name ?? '',
      id: data.country_group?.id ?? null
    }
    this.flatBid = data.flat_bid ?? null
    this.id = data.id ?? null
    this.isHotOffer = data.is_hot_offer ?? false
    this.maxDays = data.max_days ?? null
    this.maxTraffic = data.max_traffic ?? null
    this.minDays = data.min_days ?? null
    this.minTraffic = data.min_traffic ?? null
    this.originalPrice = data.original_price ?? null
    this.placementType = new PlacementType(data.placement_type)
    this.price = data.price ?? null
    this.pricingModel = data.pricing_model ?? ''
    this.site = new SiteNew(data.site)
    this.totalDiscountRatio = data.total_discount_ratio ?? null
    this.trafficPerMonth = data.traffic_per_month ?? null
    this.trafficType = data.traffic_type ?? ''
    this.days = this.minDays
    this.totalTraffic = this.minTraffic
  }

  get trafficTypeIcon() {
    return FlatDeal.trafficTypeWitchIcon[this.trafficType]
  }
  get totalDiscountRatioToPercent() {
    const totalDiscountRatioToPercent = (1 - this.totalDiscountRatio) * 100
    return `${totalDiscountRatioToPercent.toFixed(2)}%`
  }

  updatePrice(data) {
    this.days = data.days ?? null
    this.originalPrice = data.original_price ?? null
    this.price = data.price ?? null
    this.totalDiscountRatio = data.total_discount_ratio ?? null
    this.totalTraffic = data.total_traffic ?? null
  }

  static trafficTypeWitchIcon = {
    desktop: '$monitor',
    mobile: '$mobile',
    all: '$monitor-mobile'
  }
}
