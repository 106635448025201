<template>
  <apexcharts
    :type="type"
    :height="height"
    :options="computedOptions"
    :series="series"
    :width="width"
    class="custom-chart__z-index"
  />
</template>

<script>
  export default {
    name: 'CustomChart',
    components: {
      apexcharts: () => import('vue-apexcharts')
    },
    props: {
      type: {
        type: String,
        default: 'line'
      },
      height: {
        type: [Number, String],
        default: '100%'
      },
      width: {
        type: [Number, String],
        default: '100%'
      },
      options: {
        type: Object,
        default: () => ({})
      },
      series: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        defaultOptions: {
          colors: [
            this.$vuetify.theme.themes.light.info,
            this.$vuetify.theme.themes.light.primary,
            this.$vuetify.theme.themes.light.warning
          ]
        }
      }
    },
    computed: {
      computedOptions() {
        return {
          ...this.defaultOptions,
          ...this.options
        }
      }
    }
  }
</script>

<style lang="scss">
  .custom-chart__z-index > div > .apexcharts-toolbar {
    z-index: 4 !important;
  }
</style>
