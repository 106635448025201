<template>
  <custom-chart
    class="second__countries-chart"
    type="donut"
    :options="optionsCountry"
    :series="topCountriesSeries"
  />
</template>
<script>
  import CustomChart from '@/components/CustomChart.vue'

  export default {
    name: 'OfferTopCountriesChart',
    components: {
      CustomChart
    },
    props: {
      site: {
        type: Object,
        required: true
      }
    },
    computed: {
      optionsCountry() {
        return {
          chart: {
            type: 'donut'
          },
          legend: {
            position: 'right',
            fontSize: '14px',
            fontFamily: '"Roboto", sans-serif',
            labels: {
              colors: this.$vuetify.theme.themes.light.black
            },
            markers: {
              width: 10,
              height: 10
            }
          },
          labels: this.chartLabels,
          plotOptions: {
            pie: {
              customScale: 1
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: [
            '#43BCA3',
            '#85D9FD',
            '#1C4492',
            '#7888DB',
            '#DFB6F2'
          ]
        }
      },
      chartLabels() {
        return this.topCountries.map((country) => `<b>${country.name}</b> - ${country.value}%`)
      },
      topCountriesSeries() {
        return this.topCountries.map((countries) => +countries.value)
      },
      topCountries() {
        const sortedCountries = [...this.site.siteReport.similarwebTopCountries].sort((a, b) => b.value - a.value)
        return sortedCountries.map((country) => ({
          name: country.country.name ?? '',
          value: (country.value * 100).toFixed()
        }))
      }
    }
  }
</script>
