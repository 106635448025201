<template>
  <section class="third">
    <div class="third__info">
      <div class="offer-card">
        <p class="third__info-title">
          Total visits
        </p>
        <p class="third__info-value">
          {{ totalVisitsValue }}<span class="unit">m</span>
        </p>
      </div>
      <div class="offer-card">
        <p class="third__info-title">
          Page views
        </p>
        <p class="third__info-value">
          {{ pageViewsValue }}<span class="unit">m</span>
        </p>
      </div>
      <div class="offer-card">
        <p class="third__info-title">
          Site rank
        </p>
        <p class="third__info-value">
          {{ siteRankValue }}
        </p>
      </div>
      <div class="offer-card">
        <p class="third__info-title">
          Vertical/Rank
        </p>
        <p class="third__info-value">
          <span class="unit">{{ verticalRankCategory }}/</span>{{ verticalRankCategoryRank }}
        </p>
      </div>
    </div>
    <div class="third__chart">
      <div class="device-chart offer-card">
        <div class="chart-title">
          Device type, %
        </div>
        <offer-device-type-chart :site="site" />
      </div>
      <div class="gender-chart offer-card">
        <div class="chart-title">
          Gender, %
        </div>
        <offer-gender-chart :site="site" />
      </div>
    </div>
    <div class="third__campaign offer-card">
      <header class="third__campaign-header">
        <div class="header-item">
          {{ marketplaceDomain }}
        </div>
        <div class="header-item">
          {{ marketplaceFormat }}
        </div>
      </header>
      <div class="third__campaign-body">
        <div class="body-item">
          <img src="@/assets/images/offer/mouthpiece.svg" width="48" height="47" alt="Mouthpiece" />
          <div class="body-item__content">
            <div class="content-name">
              Format
            </div>
            <div class="content-value">
              {{ marketplaceFormat }}
            </div>
          </div>
        </div>
        <div class="body-item">
          <img src="@/assets/images/offer/dollar.svg" width="48" height="47" alt="Dollar" />
          <div class="body-item__content">
            <div class="content-name">
              Price model
            </div>
            <div class="content-value text-uppercase">
              {{ marketplacePriceModel }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import FlatDeal from '@/services/classes/FlatDeal.js'
  import OfferDeviceTypeChart from './OfferDeviceTypeChart.vue'
  import OfferGenderChart from './OfferGenderChart.vue'

  export default {
    name: 'OfferThird',
    components: {
      OfferGenderChart,
      OfferDeviceTypeChart
    },
    props: {
      site: {
        type: Object,
        required: true
      },
      flatDeal: {
        type: FlatDeal,
        required: true
      }
    },
    computed: {
      totalVisitsValue() {
        return toFixedByDefault(this.site.siteReport.similarwebTotalVisits / 10 ** 6)
      },
      pageViewsValue() {
        return toFixedByDefault(this.site.siteReport.similarWebPageViews / 10 ** 6)
      },
      siteRankValue() {
        return `#${this.site.siteReport.similarwebGlobalRank}`
      },
      verticalRankCategory() {
        return this.site.siteReport.similarwebCategory
      },
      verticalRankCategoryRank() {
        return this.site.siteReport.similarwebCategoryRank
      },
      marketplaceDomain() {
        return this.flatDeal.site.domain
      },
      marketplaceFormat() {
        return this.flatDeal.placementType.adFormat.name
      },
      marketplacePriceModel() {
        return this.flatDeal.pricingModel
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/offer-deal-colors.scss";

.offer-card {
  border-radius: 16px;
  background: $white-color;
  box-shadow: 4px 12px 20px 0 rgba(21, 37, 94, 0.15);
}

.third {
  background: $lighter-color;
  padding: 64px 100px;

  &__info {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    &-title {
      color: $primary-color;
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
    }
    &-value {
      color: $black-color;
      font-size: 52px;
      font-weight: 600;
      line-height: normal;

      .unit {
        font-size: 32px;
      }
    }

    & .offer-card {
      flex: 1 0 calc(100% / 4 - 16px);
      padding: 16px 28px;
    }
  }
  &__chart {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 40px;

    .chart-title {
      color: $black-color;
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
    }

    .chart-device,
    .chart-gender {
      margin-top: 40px;
    }

    & .offer-card {
      flex: 0 1 calc(100% / 2 - 16px);
      padding: 16px 28px;
    }
  }

  &__campaign {
    margin-top: 40px;

    &-header {
      border-radius: 16px;
      background: $blue-color;
      padding: 20px 32px;
      display: flex;
      align-items: center;
      text-align: center;

      .header-item {
        flex: 1 0 calc(100% / 3);
        padding: 0 16px;
        color: $white-color;
        font-size: 20px;
        font-weight: 600;
        line-height: normal;

        &:not(:last-child) {
          border-right: 2px solid $white-color;
          text-align: center;
        }
      }
    }
    &-body {
      padding: 20px 32px;
      display: flex;
      gap: 16px;

      .body-item {
        display: flex;
        flex: 1 0 calc(100% / 2 - 16px);
        gap: 24px;

        &__content {
          display: flex;
          flex-direction: column;
          line-height: normal;
          font-size: 20px;

          .content-name {
            color: $darker-color;
            font-family: 'Roboto', sans-serif;
          }
          .content-value {
            display: flex;
            align-items: center;
            gap: 8px;
            color: $black-color;
            font-weight: 600;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
</style>
