<template>
  <custom-chart
    class="chart-gender"
    type="donut"
    :options="genderOptions"
    :series="genderTopCountriesSeries"
    height="400px"
  />
</template>
<script>
  import CustomChart from '@/components/CustomChart.vue'

  export default {
    name: 'OfferGenderChart',
    components: {
      CustomChart
    },
    props: {
      site: {
        type: Object,
        required: true
      }
    },
    computed: {
      genderOptions() {
        return {
          dataLabels: {
            enabled: false
          },
          legend: {
            position: 'bottom',
            dataLabels: true,
            labels: {
              colors: this.$vuetify.theme.themes.light.black
            },
            markers: {
              width: 10,
              height: 10
            }
          },
          plotOptions: {
            pie: {
              customScale: 1
            }
          },
          labels: this.genderChartLabels,
          colors: [
            '#7888DB',
            '#85D9FD'
          ]
        }
      },
      genderTopCountriesSeries() {
        return [+this.site.siteReport.similarwebMale, +this.site.siteReport.similarwebFemale]
      },
      genderChartLabels() {
        return [
          `<b>${this.$t('marketplace.male')}</b> - ${this.gendersToPercents.male}%`,
          `<b>${this.$t('marketplace.female')}</b> - ${this.gendersToPercents.female}%`
        ]
      },
      gendersToPercents() {
        return {
          male: (this.site.siteReport.similarwebMale * 100).toFixed(),
          female: (this.site.siteReport.similarwebFemale * 100).toFixed()
        }
      }
    }
  }
</script>
