<template>
  <footer class="fourth__footer">
    <div class="fourth__footer-title">
      Contact us to buy:
    </div>
    <div class="fourth__footer-contacts">
      <a href="mailto:support@clickadilla.com" class="contact-item">
        Email
      </a>
      <a href="https://t.me/clickadillaCS" target="_blank" class="contact-item">
        Telegram
      </a>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'OfferFourthFooter'
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/offer-deal-colors.scss";

  .fourth__footer {
    padding: 16px 0 26px;
    background: $blue-color;
    position: relative;
    z-index: 1;

    &-title {
      color: $white-color;
      text-align: center;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.48);
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
    }
    &-contacts {
      display: flex;
      gap: 40px;
      justify-content: center;
      margin-top: 16px;

      .contact-item {
        color: $white-color;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
</style>
