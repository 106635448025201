<template>
  <section class="second">
    <div class="second__id">
      Offer (ID {{ flatDealId }})
    </div>
    <div class="second__domains">
      <div class="second__domains-logo offer-card">
        <img
          :src="site.logo.src"
          class="domain-image"
          width="249"
          height="86"
          alt="Domain logo"
        />
      </div>
      <div class="second__domains-info offer-card">
        <p class="domain-name">
          Domain
        </p>
        <p class="domain-host">
          {{ site.domain }}
        </p>
      </div>
    </div>
    <div class="second__countries offer-card">
      <div class="second__countries-title">
        Top 5 countries, %
      </div>
      <offer-top-countries-chart :site="site" />
    </div>
    <div class="second__channels offer-card">
      <div class="second__channels-title">
        Channel overviews, %
      </div>
      <offer-channel-overviews-chart :site="site" />
    </div>
  </section>
</template>

<script>
  import OfferTopCountriesChart from './OfferTopCountriesChart.vue'
  import OfferChannelOverviewsChart from './OfferChannelOverviewsChart.vue'

  export default {
    name: 'OfferSecond',
    components: {
      OfferChannelOverviewsChart,
      OfferTopCountriesChart
    },
    props: {
      flatDealId: {
        type: Number,
        required: true
      },
      site: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/offer-deal-colors.scss";

.offer-card {
  border-radius: 16px;
  background: $white-color;
  box-shadow: 4px 12px 20px 0 rgba(21, 37, 94, 0.15);
}
.second {
  background: $lighter-color;
  padding: 48px 100px;

  &__id {
    font-size: 32px;
    font-weight: 600;
  }
  &__domains {
    display: flex;
    gap: 20px;
    margin-top: 44px;

    .offer-card {
      flex: 1 0 calc(100% / 2 - 20px);
    }
    &-logo {
      padding: 32px 20px;

      .domain-image {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
    &-info {
      padding: 16px 20px;
      line-height: normal;

      .domain-name {
        color: $black-color;
        font-size: 20px;
        font-weight: 600;
      }
      .domain-host {
        font-family: 'Roboto', sans-serif;
        color: $primary-color;
        font-size: 32px;
        font-weight: 400;
      }
    }
  }
  &__countries {
    margin-top: 42px;
    padding: 20px 40px;

    &-title {
      color: $black-color;
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
    }
    &-chart {
      margin-top: 8px;
    }
  }
  &__channels {
    margin-top: 42px;
    padding: 20px 40px;

    &-title {
      color: $black-color;
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
    }
    &-chart {
      margin-top: 8px;
    }
  }
}
</style>
