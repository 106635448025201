<template>
  <div class="offer">
    <c-btn
      class="text-center button-print no-print"
      label="PRINT"
      color="primary"
      :loading="isLoading"
      @click="printOffer()"
    />
    <v-progress-linear v-if="isLoading" class="mt-2" indeterminate color="primary" />
    <div v-else class="print-app">
      <offer-first
        class="size-a4-format"
      />
      <offer-second
        :flat-deal-id="flatDealId"
        :site="flatDeal.site"
        class="size-a4-format"
      />
      <offer-third
        :site="flatDeal.site"
        :flat-deal="flatDeal"
        class="size-a4-format"
      />
      <offer-fourth
        :site="flatDeal.site"
        :flat-deal="flatDeal"
        class="size-a4-format"
      />
    </div>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import OfferFirst from './OfferFirst.vue'
  import OfferSecond from './OfferSecond/Index.vue'
  import OfferThird from './OfferThird/Index.vue'
  import OfferFourth from './OfferFourth/Index.vue'
  import { flatDealsRepository } from '@/services/repository-factory.js'
  import FlatDeal from '@/services/classes/FlatDeal.js'
  import handleErrors from '@/services/handleErrors.js'
  import flatDealLimitTypes from '@/types/flat-deal-limit-types.js'

  const { IMPRESSIONS, DAYS } = flatDealLimitTypes

  export default {
    name: 'Offer',
    components: {
      CBtn,
      OfferFirst,
      OfferSecond,
      OfferThird,
      OfferFourth
    },
    data() {
      return {
        isPrint: false,
        flatDeal: new FlatDeal(),
        isLoading: false,
        flatDealPrice: null
      }
    },
    computed: {
      flatDealId() {
        return +this.$route.params.id
      }
    },
    async created() {
      await this.fetchFlatDeal()
      await this.updatePriceFlatDeal()
    },
    methods: {
      async printOffer() {
        await window.print()
      },
      async updatePriceFlatDeal() {
        this.isLoading = true
        const params = {
          limit_type: this.$route.query.type,
          ...this.$route.query.type === DAYS && { days: this.$route.query.value },
          ...this.$route.query.type === IMPRESSIONS && { total_traffic: this.$route.query.value }
        }
        try {
          const response = await flatDealsRepository.price(this.flatDeal.id, params)
          this.flatDeal.updatePrice(response)
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      async fetchFlatDeal() {
        this.isLoading = true
        try {
          const response = await flatDealsRepository.show(this.flatDealId)
          this.flatDeal = new FlatDeal(response)
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

  @media print {
    .no-print {
      display: none !important;
    }
  }
  .button-print {
    margin: 50px auto;
    display: block;
  }

  @page {
    margin: 0;
  }

  *,
  *::before,
  *::after {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust:exact !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .print-app {
    font-family: 'Montserrat', sans-serif;
    width: 210mm;
    position: relative;
    overflow: hidden;
    margin-inline: auto;

    .size-a4-format {
      height: 297mm;
      width: 210mm;
    }
  }
</style>
